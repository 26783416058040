body {
    font-family: Arial, sans-serif;
}
.faq-container > h1{
    margin: 10px 0;
    color: #ff4500;
}

.faq-container {
    width: 80%;
    margin: 0 auto;
    padding: 30px;
}

.faq-item {
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
}

.faq-question {
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    background-color: #f0f0f0;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.faq-question:hover {
    background-color: #e0e0e0;
}

.faq-answer {
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 5px;
    margin-top: 5px;
}
