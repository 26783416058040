.contact-container {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
  }

  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  
  .contact-header {
    text-align: center;
    background: url('../../assets/img/banner4.jpg') no-repeat center center;
    background-position: center;
    background-color: #ccc;
    background-size: cover;
    padding: 60px 20px;
  }
  
  .contact-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .contact-header p {
    font-size: 1.2rem;
  }
  
  .contact-content {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    gap: 20px;
  }
  
  .contact-form, .contact-info {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 600px;

  }
  
  .contact-form h2, .contact-info h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .contact-form p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .contact-form .input-group {
    display: flex;
    gap: 20px;
  }

  
  
  .contact-form .input-box {
    position: relative;
    width: 100%;
  }
  
  .contact-form .input-box input,
  .contact-form .input-box textarea {
    width: 70%;
    padding: 0.7rem 0;
    margin: 1rem 0;
    padding-left: 35px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form .input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .contact-form button {
    background: #ff4b2b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .contact-form button:hover {
    background: #e03e25;
  }
  
  .contact-info p {
    margin-bottom: 10px;
    padding: 0.7rem 0;
    display: flex;
    align-items: center;
    
  }
  
  .info-icon {
    margin-right: 10px; /* Reduced margin for better alignment */
    font-size: 1.5rem;   /* Adjusted size to balance with the text */
    color: #283c8b;
  }
  
  .contact-info a {
    margin-left: 2rem; /* Added margin to align with the text after the icon */
    color: #ff4b2b;
    text-decoration: none;
    
  }
  
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  .info-icon {
    margin-right: 20px;
    font-size: 2rem;
    color: #283c8b;
  }
  

  /* Responsive Styles */
@media (max-width: 767px) {
    .contact-header {
      padding: 40px 10px; /* Adjust padding to fit smaller screens */
    }
  
    .contact-header h1 {
      font-size: 1.8rem; /* Adjust font size for smaller screens */
    }
  
    .contact-header p {
      font-size: 1rem; /* Adjust font size for smaller screens */
    }
  
    .contact-content {
      flex-direction: column; /* Stack content vertically on smaller screens */
      align-items: center; /* Center align content */
      padding: 20px; /* Adjust padding */
      gap: 20px;
    }
  
    .contact-form, .contact-info {
      width: 100%; /* Full width on smaller screens */
      max-width: 100%; /* Ensure no max-width restriction */
      padding: 20px; /* Adjust padding */
      box-sizing: border-box; /* Include padding in width calculations */
    }
  
    .contact-form .input-group {
      flex-direction: column; /* Stack input boxes vertically on smaller screens */
      gap: 20px;
    }
  
    .contact-form .input-box {
      width: 100%; /* Full width of input boxes on smaller screens */
      margin: 0; /* Remove margins */
    }
  
    .contact-form .input-box input,
    .contact-form .input-box textarea {
      width: 100%; /* Full width on smaller screens */
      padding: 0.7rem 0;
      margin: 0.5rem 0; /* Adjust margin */
      padding-left: 35px;
      box-sizing: border-box; /* Include padding in width calculations */
    }
  
    .contact-form button {
      font-size: 0.9rem; /* Adjust font size for smaller screens */
      padding: 8px 16px; /* Adjust padding */
      width: 100%; /* Full width on smaller screens */
    }
  }
  