/* Deals.css */

.header-not {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 8rem;
  background: #fff;
  margin-top: 3rem;
  max-width: 100%;
}

.header-item-not {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  text-align: center;
}

/* Add keyframes for the bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.header-item-not img {
  width: 90px;
  height: 90px;
  margin-right: 10px;
  animation: bounce 1s infinite; /* Apply the animation */
}

.header-item-not h3 {
  margin: 0;
  font-size: 22px;
  color: #333;
}

.header-item-not p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .header-item-not{
    padding: 20px 0;
  }


  .header-not {
      padding: 1rem 2rem;
  }
  
  .header-item-not img {
      width: 80px;
      height: 80px;
  }
  
  .header-item-not h3 {
      font-size: 20px;
  }
  
  .header-item-not p {
      font-size: 13px;
      margin: 10px 0;
  }
}

@media (max-width: 768px) {

  .header-item-not{
    padding: 20px 0;
  }

  .header-not {
      flex-direction: column;
      align-items: center;
      padding: 1rem;
  }
  
  .header-item-not {
      flex-direction: column;
      text-align: center;
  }
  
  .header-item-not img {
      margin-bottom: 10px;
      margin-right: 0;
  }
}

@media (max-width: 480px) {
  .header-item-not{
    padding: 20px 0;
  }

  .header-not {
      padding: 0.5rem;
      margin-top: 6rem;
  }

  
  .header-item-not img {
      width: 70px;
      height: 70px;
  }
  
  .header-item-not h3 {
      font-size: 18px;
  }
  
  .header-item-not p {
      font-size: 12px;
      margin: 10px 0;
  }
}
