.hero-section {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  overflow: hidden;
}

/* Video Background Styles */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Place the video behind the content */
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  z-index: 1; /* Place overlay above the background but below the content */
}

.hero-section .overlayy {
  position: relative; /* Ensure overlay content is above the pseudo-element */
  z-index: 2; /* Ensure overlay content is above the pseudo-element */
  padding: 40px 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section h1 {
  font-family: Arial;
  font-size: 3rem; /* Adjusted for better responsiveness */
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
}

.hero-section p {
  margin-bottom: 20px;
  max-width: 600px;
  font-family: Arial;
  font-size: 1rem; /* Adjusted for better readability on small screens */
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #FFFFFF;
}

.other-dtails {
  display: flex;
  padding: 2rem 0;
  
}

.det {
  padding: 0 2rem; /* Adjust padding for smaller screens */
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem; /* Add spacing between details on small screens */
}

.det > .icon {
  font-size: 1.5rem; /* Adjusted for better responsiveness */
  color: #f54c4c;
  animation: bounce 1s infinite; /* Apply the animation */
}

.det > .text {
  font-size: 1rem; /* Adjusted for better responsiveness */
  font-weight: 600;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.overlayy > h2 {
  font-size: 1.5rem; /* Adjusted for better readability on small screens */
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 1rem; /* Add some margin for spacing */
}

.overlayy > h1 {
  margin-bottom: 1.5rem;
}

@media (max-width: 480px) {
  .overlayy > h1 {
    font-size: 2rem;
    margin-top: 4rem; /* Adjust margin to fit content better */
  }

  .overlayy > h2 {
    font-size: 1.2rem; /* Keep h2 visible but reduce size */
    margin-bottom: 0.5rem; /* Adjust spacing */
  }

  .other-dtails {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    flex-direction: column; /* Stack items on small screens */
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .overlayy > h1 {
    font-size: 2.5rem;
    margin-top: 6rem; /* Adjust margin to fit content better */
  }

  .overlayy > h2 {
    font-size: 1.5rem; /* Keep h2 visible but reduce size */
    margin-bottom: 1rem;
  }

  .other-dtails {
    flex-direction: row; /* Keep items in a row */
    justify-content: center; /* Center items */
    flex-direction: column; /* Stack items on small screens */
  }
}
