.flight-offers {
  padding: 20px 120px;
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.header h1 {
  font-size: 32px;
  color: #333;
  margin-bottom: 1rem;
}

.header p {
  color: #777;
  font-size: 16px;
}

/* Grid Layout */
.offers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns of equal width */
  gap: 20px; /* Space between cards */
}

/* Card Styles */
.offer-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 10px 0;
}

.offer-image {
  position: relative;
  width: 100%;
  height: 200px; /* Fixed height for all images */
}

.offer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire space without distortion */
}

.offer-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e74c3c;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
}

.offer-content {
  padding: 20px;
  text-align: left;
}

.offer-content h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.offer-content p {
  margin: 5px 0;
  color: #666;
}

.price {
  color: #e74c3c;
  font-size: 20px;
  font-weight: bold;
}

.rating span {
  color: #f39c12;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .flight-offers {
    padding: 20px 20px; /* Reduce padding for smaller screens */
  }

  .offers-grid {
    grid-template-columns: 1fr; /* Stack cards in a single column */
  }

  .offer-card {
    margin-bottom: 20px; /* Add margin between stacked cards */
  }

  .offer-content {
    text-align: center; /* Center text content on smaller screens */
  }

  .offer-image {
    height: auto; /* Allow image height to adjust naturally */
  }

  .offer-tag {
    left: 50%;
    transform: translateX(-50%); /* Center the offer tag */
  }

  .offer-content h2 {
    font-size: 20px; /* Slightly smaller heading on mobile */
  }

  .price {
    font-size: 18px; /* Adjust price font size for readability */
  }
}
