.bgDark {
  background: #283C8B !important;
  padding: 2rem;
}

* {
  text-decoration: none;
  list-style-type: none;
}

.main-footer .grid {
  display: grid;
  align-items: start;
  justify-content: space-around;
  grid-template-columns: auto auto auto auto;
  margin-bottom: 2rem;
}

/* Make all links in the footer white */
.main-footer a {
  color: white; /* Set link color to white */
  text-decoration: none; /* Optional: Remove underline from links */
}

/* Optional: Hover effect for links */
.main-footer a:hover {
  text-decoration: underline; /* Optional: Add underline on hover */
}

.main-footer .grid ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
}

.main-footer .grid h2 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.payment-icon {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-icon img {
  width: 50px; /* Ensure all icons are the same size */
  height: auto; /* Maintain aspect ratio */
  margin: 0.5rem; /* Add margin for spacing */
}

.logo {
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #283C8B;
  width: 140px;
  height: 50px; /* Adjust this height as needed */
}

.main-footer {
  margin: 1rem 1rem; /* Adjust margin for better spacing on smaller screens */
  color: white;
}

.card-img {
  width: 3rem;
}

.footer-copyright {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.18);
  border-radius: 5px;
  color: white;
}

.copyright-tag {
  display: flex;
  gap: 1rem;
  font-weight: 600;
}

.footer-icons {
  display: flex;
  gap: 2rem;
}

.footer-hr {
  margin: 2rem 0rem; /* Adjust margin for better spacing on smaller screens */
  color: #010101;
}

.paragraph {
  line-height: 16.5px;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem;
  color: gainsboro;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .pro {
      margin-top: 8rem;
  }

  .flex-box {
      flex-direction: column;
  }

  .card-img {
      width: 20%;
  }

  .footer-hr {
      width: 90%;
  }

  .paragraph {
      width: 90%;
      line-height: 12.5px;
      font-size: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 1rem 0;
      color: gainsboro;
  }

  .footer-copyright {
      width: 90%;
  }
}

@media (max-width: 900px) {
  .main-footer .grid {
      grid-template-columns: unset;
      grid-template-rows: auto auto auto auto;
      text-align: center;
      gap: 5px;
  }

  .paragraph {
      text-align: center;
  }
}

@media (max-width: 600px) {
  .footer-copyright {
      flex-direction: column;
      gap: 5px;
  }

  .footer-hr {
      width: 100%;
  }

  .paragraph {
      width: 100%;
  }
}
