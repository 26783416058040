.one-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 7rem;
    background-color: #f8f9fa;
    position: -webkit-sticky; /* For Safari */
    position: sticky; /* For modern browsers */
    top: 0; /* Stick to the top */
    z-index: 1000; /* Ensure it stays above other content */
    max-width: 100vw;
}

.center {
    text-align: center;
    align-items: center;
    font-size: 1rem;
    padding: 0 1rem;
}

.logos {
    font-weight: 600;
    font-size: 1.2rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.logo-icons {
    text-align: center;
    align-items: center;
    font-size: 2rem;
    color: orangered;
}

.content {
    display: flex;
    flex-wrap: wrap;
    font-weight: 600;
    letter-spacing: 0.07rem;
    
}

.content > p {
    padding: 0 1rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.contact-no {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}


.contact-no > img {
    width: 2.8rem;
    height: 2.8rem;
}

.second-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 8rem;
    background-color: #fff;
    position: -webkit-sticky; /* For Safari */
    position: sticky; /* For modern browsers */
    top: 0; /* Stick to the top */
    z-index: 1000; /* Ensure it stays above other content */
    max-width: 100vw;
}

.nav-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.nav-bar > .link {
    padding: 0 1rem;
    text-decoration: none;
    color: gray;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.nav-bar > .link:hover {
    color: black;
}

.hamburger-menu {
    display: none;
    cursor: pointer;
}

.hamburger-icon {
    width: 2rem;
    height: 0.27rem;
    background-color: black;
    position: relative;
    transition: all 0.3s ease;
}

.hamburger-icon::before,
.hamburger-icon::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 0.27rem;
    background-color: black;
    transition: all 0.3s ease;
}

.hamburger-icon::before {
    top: -0.67rem;
}

.hamburger-icon::after {
    top: 0.67rem;
}

.hamburger-icon.open {
    background-color: transparent;
}

.hamburger-icon.open::before {
    transform: rotate(45deg);
    top: 0;
}

.hamburger-icon.open::after {
    transform: rotate(-45deg);
    top: 0;
}

.call-me {
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

/* Adjust font-size for tablets and larger phones */
@media (max-width: 1024px) {
    .call-me {
        font-size: 1.2rem;
    }
}
/* Adjust font-size for screens between 777px and 1358px */
@media (max-width: 1358px) and (min-width: 777px) {
    .call-me {
        font-size: 0.8rem;
    }

    .logos {
        font-size: 1rem;
    }
}

/* Further adjustment for very small screens */
@media (max-width: 480px) {
    .call-me {
        font-size: 0.9rem;
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .one-header {
        display: none;
    }

    .second-header {
        justify-content: space-between;
    }

    .nav-bar {
        display: none;
        flex-direction: column;
        width: 90%;
        height: 100%;
        background-color: aliceblue;
        position: fixed;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
        z-index: 999;
    }

    .nav-bar.open {
        display: flex;
        position: fixed;
    }

    .nav-bar > .link {
        padding: 3rem 1rem;
        text-align: center;
        color: black;
    }

    .hamburger-menu {
        display: block;
    }
}

@media (max-width: 480px) {
    .contact-no {
        display: none;
    }

    .contact-no > h3 {
        font-size: 1rem;
    }

    .second-header {
        padding: 0.5rem 0.625rem;
    }

    .nav-bar > .link {
        padding: 2rem 0;
        font-size: 1rem;
    }
}

@media (min-width: 482px) and (max-width: 567px) {
    .one-header {
        padding: 0.8rem 1rem;
    }

    .contact-no {
        display: none;
        padding: 0 0.5rem;

    }

    .logos {
        font-size: 1rem;
    }
}



@media (min-width: 567px) and (max-width: 1245px) {
  

    .contact-no {
        display: none;
       
    }

   
}
