.about-main-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem 0 10.9rem;
    gap: 9.7rem;
    line-height: normal;
    letter-spacing: normal;
    font-size: 1rem;
    color: white;
    background-color: #efefef;
}

.about-main-section {
    position: relative;
   
}

.about-img-container {
    position: relative;
}

.about-img-container img {
    width: 100%;
    object-fit: cover;

    max-height: 300px;
}

.about-text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: clamp(2rem, 5vw, 4rem);
    font-weight: 600;
    text-align: center;
    
}

.about-header {
    color: black;
    font-weight: 600;
    padding: 3rem 1rem 0 1rem;
    text-align: center;
    margin-bottom: 2rem;
    font-size: clamp(1.5rem, 4vw, 2rem);
}

.about-des {
    color: #212529;
    font-size: clamp(0.8rem, 2vw, 0.9rem);
    letter-spacing: 0.6px;
    padding: 1rem;
    text-align: start;
    margin-top: 4rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 2rem auto;
    max-width: 80%;
    border-radius: 20px;
}

.ul {
    margin-left: 6rem;
}

.about-des > p {
    padding: 2rem 1rem;
}

.about-des-two {
    margin-top: 4rem;
    color: black;
    font-size: clamp(0.7rem, 1.5vw, 0.8rem);
    letter-spacing: 0.8px;
    padding: 0 1rem;
    text-align: start;
}

/* Responsive Styles */
@media (min-width: 480px) {
    .about-des > p {
        padding: 1rem 2rem;
    }
}

@media (min-width: 768px) {
    .about-header {
        padding: 3rem 1rem 0 1rem;
        text-align: center;
    }

    .about-des,
    .about-des-two {
        padding: 0 5rem;
        text-align: start;
    }

    .about-des > p {
        padding: 1rem 4rem;
    }
}

@media (min-width: 1024px) {
    .about-header {
        padding: 3rem 0 0 5rem;
        text-align: left;
    }

    .about-des,
    .about-des-two {
        padding: 0 12rem;
    }

    .about-des > p {
        padding: 1rem 6rem;
    }
}
