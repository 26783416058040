.service-main-container {
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 40px;
    flex-wrap: wrap; /* Allow wrapping to the next line on smaller screens */
    padding: 50px 0;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .service-main-container {
      flex-direction: column;
      align-items: center; /* Center items horizontally */
     gap: 20;
     
    }
  }
  
  /* Optional: Add styles for very small screens */
  @media (max-width: 480px) {
    .service-main-container {
    gap: 20;
    
    }
  }
  